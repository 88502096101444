import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		window.addEventListener('hashchange', (e) => {

			var navItem = document.getElementById('pageid');
			console.log("navItem: " + navItem);

			if (navItem) {
				const currentURL = new URL(window.location.toLocaleString()).toString();

				const hash = window.location.hash;
				console.log('hash:' + hash);
				if (hash && hash.includes('Deutschland')) {
					$('#navregions').addClass('navactive');
					$('#navsearch').removeClass('navactive');
				} else {
					$('#' + navItem.className).addClass('navactive');
					$('#navregions').removeClass('navactive');
				}
			}

		}, false);

	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};