import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

import {
	fireFormSubmit
}
from 'voUtils/TagEx.js';

import {
	doPost
}
from 'voUtils/RestEx.js';
import {
	findPreviousSiblingByName
}
from 'voUtils/BrowserEx.js';

export default {
	voVueComponent: 'voffice-form',
	data: function () {
		var fd = VOFFICE.testmode ? {
			forename: 'John',
			surname: 'Doe',
			email: 'test@test.de',
			mobile: '342344 234234',
			message: 'Hallo World!',
		} : {};
		return {
			formData: fd,
			status: 'ready',
			travelperiod: '',
			guests: {
				adults: '',
				children: '',
				babys: '',
				petsCount: ''
			},
			error: undefined

		};
	},
	props: {
		subject: String,
		ngaction: String
	},
	computed: {
		periodLabel: function () {
			let label = '';
			if (this.formData.from) {
				label = this.voFormatDate(this.formData.from, 'withDay');
			}
			if (this.formData.till) {
				label += ' - ' + this.voFormatDate(this.formData.till, 'withDay');
			}

			return label;
		},
		customGuestLabel: function () {
			let label;
			var sd = this.guests;
			if (sd.adults) {
				label = sd.adults + ' ' + this.voMsgPl('adult.pl', sd.adults);
				this.formData.adults = sd.adults;
				if (sd.children > 0) {
					label += ', ' + sd.children + ' ' + this.voMsgPl('child.pl', sd.children);
					this.formData.children = sd.children;
				}
				if (sd.babys > 0) {
					label += ', ' + sd.babys + ' ' + this.voMsgPl('baby.pl', sd.babys);
					this.formData.babys = sd.babys;
				}
				if (sd.petsCount > 0) {
					label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);
					this.formData.petsCount = sd.petsCount;
				}
			}
			return label;
		},

		setUnit: function () {
			let el = document.querySelector("[data-unit-code]");
			if (el) {
				this.formData.object = el.getAttribute('data-unit-code');
			}
		}

	},
	mounted: function () {


		var labels = {};
		var order = [];

		this.$el.querySelectorAll('[data-formdata-field]').forEach((el) => {
			var field = el.getAttribute('data-formdata-field');
			let label = el.getAttribute('voffice-form-field-label');

			if (!label) {
				let labelEl = findPreviousSiblingByName(el, 'label');
				if (!labelEl) {
					let id = el.getAttribute('id');
					if (id) {
						labelEl = this.$el.querySelector('[for="' + id + '"]');
					}
				}
				if (labelEl) {
					label = labelEl.textContent;
				}

			}

			if (label) {
				labels[field] = label;
			} else {
				V.log(`could not find label for field ${field}, please use label, 'for' or 'voffice-form-field-label' attribute`);
			}

			order.push(field);



		});

		this.labels = labels;
		this.order = order;
	},


	methods: {

		checkEmail: function (e) {

			var email = e.target.value;

			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				$('#email-error').remove();
				$(":submit").prop('disabled', false);
				return true;
			} else {
				$(":submit").prop('disabled', true);
				$("#input_email").after('<div id="email-error" style="color:white; background: red; font-size: 13px; padding:2px 15px; margin-top:5px; border-radius: 5px">' + this.voMsg("email.invalid") + '</div>');
				return false;
			}
		},

		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},

		scrollToResult: function () {
			if (document.getElementById('inquiry')) {
				$('html, body').animate({
					scrollTop: $("#inquiry").offset().top - 300
				}, 1000);
			}
		},

		submit: function () {

			this.status = 'submitting';

			var data = {
				subject: this.subject,
				form: DateEx.formatDates(this.formData, undefined, VOFFICE.mlang),
				search: DateEx.encodeDates(this.$parent.searchData),
				unit: this.$parent.getUnitId && this.$parent.getUnitId(),
				labels: this.labels,
				bookingdata: this.$parent.bookingData,
				order: this.order
			};


			fireFormSubmit(data);
			this.error = undefined;

			doPost(this.ngaction || 'saveMessage', data)
				.then((res) => {

					this.status = 'done';
					this.scrollToResult();


				}).catch(e => {
					this.status = 'failed';
					this.error = e;
				});


		}
	},


};